import "./App.css";
import "swiper/swiper-bundle.css";
import Router from "./router";
import {
  BrowserRouter,
  HashRouter,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import routesConfig from "./router/config";
import Frame from "./components/Frame";

import Home from "./views/Home";
import Game from "./views/Game";
import Mining from "./views/Mining";
import { AppConfig } from "./config";
import { UseWalletProvider } from "use-wallet";

import "./i18n";
function App() {
  return (
    <UseWalletProvider
      chainId={AppConfig.chainId}
      connectors={{
        walletconnect: { rpcUrl: AppConfig.rpcUrl[AppConfig.chainId] },
        ledger: { url: AppConfig.rpcUrl[AppConfig.chainId] },
      }}
    >
      <BrowserRouter>
        <Switch>
          <Frame menus={routesConfig.menus}>
            <Router />
          </Frame>
        </Switch>
      </BrowserRouter>
    </UseWalletProvider>
  );
}

export default App;
