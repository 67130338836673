import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import Topbar from "../Topbar";
const Frame = (props) => {
  useEffect(() => {}, []);
  return (
    <div>
      <Topbar menus={props.menus} props={props}></Topbar>
      <div>{props.children}</div>
    </div>
  );
};
export default withRouter(Frame);
