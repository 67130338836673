import React, { useState, useEffect, useCallback, useRef } from "react";
import "./game.less";
import { useTranslation } from "react-i18next";

import tbg1 from "../../assets1/game/tbg1.png";
import tbg2 from "../../assets1/game/tbg2.png";
import tbg3 from "../../assets1/game/tbg3.png";
import tbg4 from "../../assets1/game/tbg4.png";
import tbg5 from "../../assets1/game/tbg5.png";
import tbg6 from "../../assets1/game/tbg6.png";

import lp from "../../assets2/game/lp.png";
import lp_ac from "../../assets2/game/lp_ac.png";
import sc from "../../assets2/game/sc.png";
import sc_ac from "../../assets2/game/sc_ac.png";
import lp_copy_ac from "../../assets-en/game/lp_copy_ac.png";
import lp_copy from "../../assets-en/game/lp_copy.png";
import curr_copy_ac from "../../assets-en/game/curr_copy_ac.png";
import curr_copy from "../../assets-en/game/curr_copy.png";
import rules_en from "../../assets-en/rules_en.png";
import rules_zh from "../../assets-en/rules_zh.png";

import god_of from "../../assets2/game/god_of.png";
import kill_gif from "../../assets2/game/kill.gif";

import { message, Tabs } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

import { initContracts, globalParam, address } from "../../chain/config";
import BigNumber from "bignumber.js";
import { AppConfig } from "../../config";
import useWallet from "use-wallet";
import { ImgObj } from '../Property/tou'
import tou1_img from '../../assets1/game_new/tou1.png'
import tou2_img from '../../assets1/game_new/tou2.png'
import tou3_img from '../../assets1/game_new/tou3.png'
import tou4_img from '../../assets1/game_new/tou4.png'
import tou5_img from '../../assets1/game_new/tou5.png'
import tou6_img from '../../assets1/game_new/tou6.png'

import lp_tou1 from '../../assets1/game_new/lp_tou1.png'
import lp_tou2 from '../../assets1/game_new/lp_tou2.png'
import lp_tou3 from '../../assets1/game_new/lp_tou3.png'
import lp_tou4 from '../../assets1/game_new/lp_tou4.png'
import { useForm } from "antd/lib/form/Form";

const { TabPane } = Tabs;

SwiperCore.use(Navigation);


const lang = localStorage.getItem("lang") || "en";
const Game = (props) => {
  const [widthAc, setWidthAc] = useState(document.body.clientWidth || '100%');
  const { account } = useWallet();
  const { t } = useTranslation();
  const { showParams } = props.match.params;
  // 单币副本模拟数据
  const singlecurrencycopys = [
    {
      id: 0,
      top_text1: t('The_interstellar1'),
      top_text2: t('The_interstellar2'),
      top_text3: t('The_interstellar3'),
      top_text4: t('The_interstellar4'),
      top_text5: t('Game0fix'),
      top_text6: t('Game1fix'),
      top_text7: t('Game2fix'),
      top_text8: t('Game3fix'),
      title: "GWC",
      img: tbg4,
      peopleNum: 0,
      participants: 11,
      winMember:10,
      loserMember:1,
      consume: "50GWC",
      top_img: lp_tou4,
      ava_img1: ImgObj.tou1,
      ava_img2: ImgObj.tou2,
      ava_img3: ImgObj.tou3,
      ava_img4: ImgObj.tou4,
      ava_img5: ImgObj.tou5,
      balance: 0,
      status: 0,
      first: 1,
      coin: "gwc",
      pool: "ethpool",
      game: "i0game",
      allowance: 0,
    },
    {
      id: 1,
      top_text1: t('The_interstellar1'),
      top_text2: t('The_interstellar2'),
      top_text3: t('The_interstellar3'),
      top_text4: t('The_interstellar4'),
      top_text5: t('Game0fix'),
      top_text6: t('Game1fix'),
      top_text7: t('Game2fix'),
      top_text8: t('Game3fix'),
      title: "BTY",
      img: tbg4,
      winMember:10,
      loserMember:1,
      peopleNum: 0,
      participants: 11,
      consume: "20BTY",
      ava_img1: ImgObj.tou3,
      ava_img2: ImgObj.tou5,
      ava_img3: ImgObj.tou1,
      ava_img4: ImgObj.tou2,
      ava_img5: ImgObj.tou5,
      balance: 0,
      status: 0,
      top_img: lp_tou4,
      coin: "bnb",
      pool: "ethpool",
      game: "i1game",
      allowance: 0,
    },
    
    {
      id: 2,
      top_text1: t('The_interstellar1'),
      top_text2: t('The_interstellar2'),
      top_text3: t('The_interstellar3'),
      top_text4: t('The_interstellar4'),
      top_text5: t('Game0fix'),
      top_text6: t('Game1fix'),
      top_text7: t('Game2fix'),
      top_text8: t('Game3fix'),
      title: "GWC",
      img: tbg5,
      peopleNum: 0,
      participants: 11,
      winMember:10,
      loserMember:1,
      consume: "200GWC",
      ava_img1: ImgObj.two_tou4,
      ava_img2: ImgObj.two_tou3,
      ava_img3: ImgObj.two_tou2,
      ava_img4: ImgObj.two_tou1,
      ava_img5: ImgObj.two_tou5,
      top_img: tou6_img,
      balance: 0,
      status: 0,
      coin: "gwc",
      pool: "btcpool",
      game: "i2game",
      allowance: 0,
    },
    {
      id: 3,
      top_text1: t('The_interstellar1'),
      top_text2: t('The_interstellar2'),
      top_text3: t('The_interstellar3'),
      top_text4: t('The_interstellar4'),
      top_text5: t('Game0fix'),
      top_text6: t('Game1fix'),
      top_text7: t('Game2fix'),
      top_text8: t('Game3fix'),
      title: "GWC",
      img: tbg5,
      peopleNum: 0,
      participants: 11,
      winMember:10,
      loserMember:1,
      consume: "500GWC",
      ava_img1: ImgObj.two_tou5,
      ava_img2: ImgObj.two_tou4,
      ava_img3: ImgObj.two_tou3,
      ava_img4: ImgObj.two_tou1,
      ava_img5: ImgObj.two_tou2,
      top_img: lp_tou4,
      balance: 0,
      status: 0,
      coin: "gwc",
      pool: "btcpool",
      game: "i3game",
      allowance: 0,
    },
    {
      id: 4,
      top_text1: t('The_interstellar1'),
      top_text2: t('The_interstellar2'),
      top_text3: t('The_interstellar3'),
      top_text4: t('The_interstellar4'),
      top_text5: t('Game0fix'),
      top_text6: t('Game1fix'),
      top_text7: t('Game2fix'),
      top_text8: t('Game3fix'),
      title: "GWC",
      img: tbg5,
      peopleNum: 0,
      participants: 10,
      winMember:5,
      loserMember:5,
      consume: "100GWC",
      ava_img1: ImgObj.two_tou4,
      ava_img2: ImgObj.two_tou2,
      ava_img3: ImgObj.two_tou5,
      ava_img4: ImgObj.two_tou1,
      ava_img5: ImgObj.two_tou3,
      top_img: tou1_img,
      balance: 0,
      status: 0,
      coin: "gwc",
      pool: "btcpool",
      game: "i4game",
      allowance: 0,
    },
    {
      id: 5,
      top_text1: t('The_interstellar1'),
      top_text2: t('The_interstellar2'),
      top_text3: t('The_interstellar3'),
      top_text4: t('The_interstellar4'),
      top_text5: t('Game0fix'),
      top_text6: t('Game1fix'),
      top_text7: t('Game2fix'),
      top_text8: t('Game3fix'),
      title: "GWC",
      img: tbg5,
      peopleNum: 0,
      participants: 10,
      winMember:5,
      loserMember:5,
      consume: "500GWC",
      ava_img1: ImgObj.two_tou1,
      ava_img2: ImgObj.two_tou4,
      ava_img3: ImgObj.two_tou5,
      ava_img4: ImgObj.two_tou2,
      ava_img5: ImgObj.two_tou3,
      top_img: tou6_img,
      balance: 0,
      status: 0,
      coin: "gwc",
      pool: "btcpool",
      game: "i5game",
      allowance: 0,
    },
    {
      id: 6,
      top_text1: t('The_interstellar1'),
      top_text2: t('The_interstellar2'),
      top_text3: t('The_interstellar3'),
      top_text4: t('The_interstellar4'),
      top_text5: t('Game0fix'),
      top_text6: t('Game1fix'),
      top_text7: t('Game2fix'),
      top_text8: t('Game3fix'),
      title: "BTY",
      img: tbg5,
      peopleNum: 0,
      participants: 10,
      winMember:5,
      loserMember:5,
      consume: "20BTY",
      ava_img1: ImgObj.two_tou3,
      ava_img2: ImgObj.two_tou2,
      ava_img3: ImgObj.two_tou1,
      ava_img4: ImgObj.two_tou4,
      ava_img5: ImgObj.two_tou5,
      top_img: lp_tou4,
      balance: 0,
      status: 0,
      coin: "bnb",
      pool: "btcpool",
      game: "i6game",
      allowance: 0,
    },
    // {
    //   id: 3,
    //   title: "BNB",
    //   img: tbg6,
    //   top_text: t('BNB_monsters'),
    //   peopleNum: 0,
    //   participants: 10,
    //   top_img: tou3_img,
    //   ava_img1: ImgObj.four_tou1,
    //   ava_img2: ImgObj.four_tou2,
    //   ava_img3: ImgObj.four_tou3,
    //   ava_img4: ImgObj.four_tou4,
    //   ava_img5: ImgObj.four_tou5,
    //   consume: "0.01BNB",
    //   balance: 0,
    //   status: 0,
    //   coin: "bnb",
    //   pool: "bnblppool",
    //   game: "bnb30game",
    //   allowance: 1,
    // },
    // {
    //   id: 5,
    //   top_text: t('Et_monsters'),
    //   title: "USDT",
    //   img: tbg6,
    //   peopleNum: 0,
    //   participants: 10,
    //   top_img: tou5_img,
    //   consume: "3USDT",
    //   ava_img1: ImgObj.four_tou1,
    //   ava_img2: ImgObj.four_tou2,
    //   ava_img3: ImgObj.four_tou3,
    //   ava_img4: ImgObj.four_tou4,
    //   ava_img5: ImgObj.four_tou5,
    //   balance: 0,
    //   status: 0,
    //   coin: "usdt",
    //   pool: "filpool",
    //   game: "usdt30game",
    //   allowance: 0,
    // },
    // {
    //   id: 3,
    //   top_text: t('BNB_monsters'),
    //   title: "BNB",
    //   img: tbg6,
    //   peopleNum: 0,
    //   participants: 3,
    //   consume: "0.1BNB",
    //   top_img: tou2_img,
    //   ava_img1: ImgObj.three_tou1,
    //   ava_img2: ImgObj.three_tou2,
    //   ava_img3: ImgObj.three_tou3,
    //   ava_img4: ImgObj.three_tou4,
    //   ava_img5: ImgObj.three_tou5,
    //   balance: 0,
    //   status: 0,
    //   coin: "bnb",
    //   pool: "bnblppool",
    //   game: "bnb10game",
    //   allowance: 0,
    // },
   
    // {
    //   id: 6,
    //   top_text: t('A_lang_number'),
    //   title: "USDT",
    //   top_img: tou4_img,
    //   ava_img1: ImgObj.tou1,
    //   ava_img2: ImgObj.tou2,
    //   ava_img3: ImgObj.tou3,
    //   ava_img4: ImgObj.tou4,
    //   ava_img5: ImgObj.tou5,
    //   img: tbg6,
    //   peopleNum: 0,
    //   participants: 3,
    //   consume: "5USDT",
    //   balance: 0,
    //   status: 0,
    //   coin: "usdt",
    //   pool: "mdxpool",
    //   game: "usdt10game",
    //   allowance: 0,
    // },
   
    // {
    //   id: 7,
    //   top_text:t('The_interstellar'),
    //   title: "GWC",
    //   img: tbg5,
    //   peopleNum: 0,
    //   participants: 3,
    //   consume: "100GWC",
    //   ava_img1: ImgObj.two_tou1,
    //   ava_img2: ImgObj.two_tou2,
    //   ava_img3: ImgObj.two_tou3,
    //   ava_img4: ImgObj.two_tou4,
    //   ava_img5: ImgObj.two_tou5,
    //   top_img: tou6_img,
    //   balance: 0,
    //   status: 0,
    //   coin: "gwc",
    //   pool: "btcpool",
    //   game: "textgame",
    //   allowance: 0,
    // },

    // {
    //   id: 7,
    //   title: "USDT",
    //   img: tbg6,
    //   peopleNum: 0,
    //   participants: 30,
    //   consume: "2USDT",
    //   balance: 0,
    //   status: 0,
    //   coin: "usdt",
    //   pool: "gwcpool",
    //   game: "usdt30game",
    //   allowance: 0,
    // },
  ];
  // LP副本模拟数据
  let LPcopys = [
    // {
    //   id: 0,
    //   title: "GWC-USDT LP",
    //   img: tbg3,
    //   top_img: lp_tou1,
    //   top_text: t('The_interstellar_s_lp'),
    //   ava_img1: ImgObj.tou1,
    //   ava_img2: ImgObj.tou2,
    //   ava_img3: ImgObj.tou3,
    //   ava_img4: ImgObj.tou4,
    //   ava_img5: ImgObj.tou5,
    //   peopleNum: 0,
    //   participants: 10,
    //   consume: "1LP",
    //   balance: 0,
    //   status: 1,
    //   // coin: "ethlp",
    //   coin: 'lp',
    //   pool: "ethlppool",
    //   game: "ethusdtgame",
    //   allowance: 0,
    // },
    // {
    //   id: 1,
    //   title: "GWC-USDT LP",
    //   img: tbg2,
    //   top_img: lp_tou2,
    //   top_text: t('The_interstellar_lp'),
    //   ava_img1: ImgObj.two_tou1,
    //   ava_img2: ImgObj.two_tou2,
    //   ava_img3: ImgObj.two_tou3,
    //   ava_img4: ImgObj.two_tou4,
    //   ava_img5: ImgObj.two_tou5,
    //   peopleNum: 0,
    //   participants: 4,
    //   consume: "5LP",
    //   balance: 0,
    //   status: 0,
    //   // coin: "gwclp",
    //   coin: 'lp',
    //   pool: "btclppool",
    //   game: "gwcusdtgame",
    //   allowance: 0,
    // },
    // {
    //   id: 2,
    //   title: "GWC-USDT LP",
    //   img: tbg1,
    //   top_img: lp_tou3,
    //   top_text: t('The_interstellar_lp'),
    //   ava_img1: ImgObj.tou1,
    //   ava_img2: ImgObj.tou2,
    //   ava_img3: ImgObj.tou3,
    //   ava_img4: ImgObj.tou4,
    //   ava_img5: ImgObj.tou5,
    //   peopleNum: 0,
    //   participants: 4,
    //   consume: "10LP",
    //   balance: 0,
    //   status: 0,
    //   // coin: "btclp",
    //   coin: 'lp',
    //   pool: "dotlppool",
    //   game: "btcusdtgame",
    //   allowance: 0,
    // },
    // {
    //   id: 3,
    //   title: "BNB-GWC LP",
    //   img: tbg1,
    //   top_img: c,
    //   top_text: t('BNB_monsters_big'),
    //   ava_img1: ImgObj.four_tou1,
    //   ava_img2: ImgObj.four_tou2,
    //   ava_img3: ImgObj.four_tou3,
    //   ava_img4: ImgObj.four_tou4,
    //   ava_img5: ImgObj.four_tou5,
    //   peopleNum: 0,
    //   participants: 50,
    //   consume: "50LP",
    //   balance: 0,
    //   status: 0,
    //   coin: "bnb",
    //   pool: "fillppool",
    //   game: "bnb30game",
    //   allowance: 0,
    // },
    // {
    //   id: 4,
    //   title: "FIL-USDT LP",
    //   img: tbg1,
    //   peopleNum: 0,
    //   participants: 10,
    //   consume: "0.05LP",
    //   balance: 0,
    //   status: 0,
    //   coin: "fillp",
    //   pool: "bnblppool",
    //   game: "filusdtgame",
    //   allowance: 0,
    // },
    // {
    //   id: 5,
    //   title: "BNB-USDT",
    //   img: tbg1,
    //   peopleNum: 0,
    //   participants: 10,
    //   consume: "1LP",
    //   balance: 0,
    //   status: 0,
    //   coin: "bnblp",
    //   pool: "mdxlppool",
    //   game: "bnbusdtgame",
    //   allowance: 0,
    // },
    // {
    //   id: 6,
    //   title: "MDX-USDT",
    //   img: tbg1,
    //   peopleNum: 0,
    //   participants: 10,
    //   consume: "5LP",
    //   balance: 0,
    //   status: 0,
    //   coin: "gwclp",
    //   pool: "gwclppool",
    //   game: "mdxusdtgame",
    //   allowance: 0,
    // },
  ];


  const[isVisibleGif, setIsVisibleGif] = useState(false)
  const [singlecurrencycopy, setSing] = useState(singlecurrencycopys);
  const [LPcopy, setLPcopys] = useState(LPcopys);
  // 选中的副本
  const [activeCopy, setActiveCopy] = useState("1");
  // 跳转路由
  const routeJump = (item) => {
    console.log('跳转的值', item);
    let objnew = Object.assign(item, { h_address: address[item.game], acc: account })
    props.history.push(`/game/info/${item.title}`);
    sessionStorage.setItem("GameItem", JSON.stringify(objnew));
  };

  //初始化加载
  const getPoolInfo = useCallback(() => {
    setWidthAc(document.body.clientWidth)
    if (!account) return;
    
    console.log('get game info...');
    let a = singlecurrencycopy.map((item) => {
      console.log(item.game,account)
      initContracts(item.game)
        .contract.methods.getInfo(account,0,0)
        .call()
        .then((r) => {
          console.log('game info:',r)
          item.participants = r[0];
          item.peopleNum = r[1];

        });

      if (item.coin === "bnb") {

        // console.log('222222,coin:',item.coin)
        item.allowance = 1;
        initContracts("eth")
          .web3.eth.getBalance(account)
          .then((bal) => {
            // console.log('bbbbbbbnb bal:',bal);
            item.balance = new BigNumber(bal)
              .div(new BigNumber(10).pow(18))
              .toFixed(globalParam.showDecimal);
          });
      } else {
        // console.log('3333333,coin:',item.coin,address[item.game],address[item.coin].decimal)
        initContracts(item.coin)
          .contract.methods.allowance(account, address[item.game])
          // .contract.methods.allowance(account,'0xc38d800FBcE3Fa48727566DeFBB42C7323ABb1B0')
          .call()
          .then((allow) => {
            // console.log('检查授权值',allow);
            item.allowance = allow;
          });
        initContracts(item.coin)
          .contract.methods.balanceOf(account)
          .call()
          .then((bal) => {
            item.balance = new BigNumber(bal)
              .div(new BigNumber(10).pow(address[item.coin].decimal))
              .toFixed(globalParam.showDecimal);
          });
      }

      return item;
    });

    let b = LPcopy.map((item) => {
      console.log('lpp...................')
      initContracts(item.game)
        .contract.methods.getInfo(account,0,0)
        .call()
        .then((r) => {
          item.participants = r[0];
          item.peopleNum = r[1];

          // if (r[2] === true) {
          //   item.status = 1;
          // } else {
          //   item.status = 0;
          // }
        });

      if (item.coin === "bnb") {
        // console.log('5555555,coin:',item.coin,item)
        item.allowance = 1;
        initContracts("eth")
          .web3.eth.getBalance(account)
          .then((bal) => {
            console.log('item:',item,'bal:',bal);
            item.balance = new BigNumber(bal)
              .div(new BigNumber(10).pow(18))
              .toFixed(globalParam.showDecimal);
          });
      } else {
        // console.log('6666666666,coin:',item.coin,item)
        initContracts(item.coin)
          .contract.methods.allowance(account, address[item.game])
          // .contract.methods.allowance(account,'0xc38d800FBcE3Fa48727566DeFBB42C7323ABb1B0')
          .call()
          .then((allow) => {
            item.allowance = allow;
          });
        initContracts(item.coin)
          .contract.methods.balanceOf(account)
          .call()
          .then((bal) => {
            item.balance = new BigNumber(bal)
              .div(new BigNumber(10).pow(address[item.coin].decimal))
              .toFixed(globalParam.showDecimal);
          });
      }
      return item;
    });

    setSing(a);
    setLPcopys(b);
  }, [account, singlecurrencycopy, LPcopy, setLPcopys, setSing]);

  //授权操作，如果对应list中的allowance =0，就需要先授权
  const approveCoin = (item) => {
    let game = item.game;
    // console.log('测试授权', item.coin)
    initContracts(item.coin)
      .contract.methods.approve(
        address[game],
        "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
      )
      .send({
        from: account,

      })
      .then(() => {
        item.allowance = 1;
        message.success(t('Authorization_successful'))
      });
  };

  // 数据重新排序
  const rearrange = (data) => {
    let arr1 = [];
    let arr2 = [];
    data.forEach((item) => {
      if (item.status === 1) {
        arr1.push(item);
      } else {
        arr2.push(item);
      }
    });
    return (data = [...arr1, ...arr2]);
  };
  //点击击杀的时候判断一下，如果返回true，就已经击杀过了
  const checkJoinRepeat = async (item, user) => {
    let joined = await initContracts(item.game)
      .contract.methods.isJoin(user)
      .call();
    return joined;
  };

  //击杀boss调用，需先判断是否授权过，授权之后显示击杀
  const killBoss = async (item) => {
    // console.log('bnb击杀', item.game);
    if (item.coin == 'bnb') {
      const play_value = await initContracts(item.game).contract.methods.payAmount().call()
      // console.log('bnb合约方法',play_value);

    await  initContracts(item.game)
        .contract.methods.normal()
        .send({
          from: account,
          value: play_value,

        })

          message.success(t('successful_kill'))
          item.status = 1;
          item.peopleNum++;
          isStatus(account);

      return
    }

   await initContracts(item.game)
      .contract.methods.normal()
      .send({ from: account,
       })


        message.success(t('successful_kill'))
        item.status = 1;
        item.peopleNum++;
        isStatus(account);
        // item.balance = new BigNumber(item.balance)
        //   .minus(new BigNumber(parseFloat(item.consume)))
        //   .toFixed(globalParam.showDecimal);

  };

  // 是否需要授权
  const authorization = async (item) => {
    // console.log('判断是否授权需要',item);
    if (item.allowance == 0 && item.coin !== 'bnb') {
      // console.log('需要授权');
      approveCoin(item);
      return;
    }
    // 击杀
    if (item.status !== 1 || item.status !== 2) {
      // setIsVisibleGif(prev => !prev)
      console.log('击杀gogogoog', isVisibleGif)
      try {
        const kill = await checkJoinRepeat(item, account);
        if (kill) {
          message.warning(t("Duplicate_kill"));
          // setIsVisibleGif(!isVisibleGif)
        } else {
        
          setIsVisibleGif(true)

          console.log('killlllll',isVisibleGif);
         await killBoss(item);
         
        }
      } catch(e) {
        console.log('catch ->', isVisibleGif)
      }finally {
        setIsVisibleGif(false)

      }
    }
  };

  // 判断status状态
  const isStatus = (account) => {
    // console.log("判断status状态");
    if (!account) return;
    singlecurrencycopy.map(async (item) => {
      const kill = await checkJoinRepeat(item, account);
      if (kill) {
        item.status = 1;
        if (item.first == 1) {
          item.first = -1;
        }
      } else {
        item.status = 0;
      }
    });
    LPcopy.map(async (item) => {
      const kill = await checkJoinRepeat(item, account);
      if (kill) {
        item.status = 1;
      } else {
        item.status = 0;
      }
    });
    setSing(singlecurrencycopys);
    setLPcopys(LPcopys);
  };

  useEffect(() => {
    setSing(rearrange(singlecurrencycopy));
    getPoolInfo();
    const timer = setInterval(() => getPoolInfo(), 15e3);
    return () => {
      clearInterval(timer);
    };
  }, [account]);

  useEffect(() => {
    // console.log("effect :", account);
    isStatus(account);
  }, [account]);

  // useEffect(()=>{

    
  // },[isVisibleGif])
  // console.log('lp副本',LPcopy);
  // console.log('单币副本',singlecurrencycopys)
  return (
    <div className="game">
      {/* 背景 */}
      {/* <img src={gamebg} alt="" className="game_bg" /> */}
      <div className="game_top">
        <div className="top_img">
          <img src={god_of} alt="" />
        </div>
        <div className="tab_title">
          <div
            className="tab_lp"
            onClick={() => {
              setActiveCopy("2");
            }}
          >
            <img
              src={
                lang === "zh"
                  ? activeCopy === "2"
                    ? lp_ac
                    : lp
                  : activeCopy === "2"
                    ? lp_copy_ac
                    : lp_copy
              }
              alt=""
              className={activeCopy !== "2" ? "tab_img" : ""}
            />
          </div>
          <div
            className="tab_sc"
            onClick={() => {
              setActiveCopy("1");
            }}
          >
            <img
              src={
                lang === "zh"
                  ? activeCopy === "1"
                    ? sc_ac
                    : sc
                  : activeCopy === "1"
                    ? curr_copy_ac
                    : curr_copy
              }
              className={activeCopy !== "1" ? "tab_img" : ""}
              alt=""
            />
          </div>
        </div>
      </div>
      {/* 中间内容部分 */}
      <div className="game_con">
        <div className="game_con_tab">
          <Tabs activeKey={activeCopy}>
            <TabPane key="2">
              <div className="LP_copy">
                <Swiper
                  spaceBetween={30}
                  slidesPerView={3}
                  allowTouchMove={false}
                  navigation
                >
                  {/* 轮播每一项 lp副本 */}
                  {LPcopy.map((item) => {
                    return (
                      <SwiperSlide
                        key={item.id}
                        
                        className={
                          item.status === 0
                            ? ""
                            : item.status === 1
                              ? "swiper-slide_kaijianges"
                              : "swiper-slide_canyu"
                        }
                      >
                        <div style={{ width: widthAc }} className="borderTopBox">
                          <div className="border_topes">
                            {t('monster_profile')}:<br />
                            {item.top_text1}{item.title}
                            {item.top_text2}{item.title}
                            {item.top_text3}{item.title}
                            {item.top_text4}
                            <img className={lang == 'zh' ? 'b_img' : 'b_img_en'} src={item.top_img} alt="" />
                          </div>
                        </div>
                        <div className="currency_title">
                          <span>{item.title}</span>
                        </div>
                        <div className="currency_img"></div>
                        <div className="currency_info">
                          <p>
                            {t("The_participants")}
                            <span>
                              {item.peopleNum}/{item.participants}
                            </span>
                          </p>
                          <p>
                            {t("Expenditure_exploration")}
                            <span>{item.consume}</span>
                          </p>
                          <p>
                            {t("Your_balance")}
                            <span>{item.balance}LP</span>
                          </p>
                        </div>
                        <div className="currency_btn">
                          <div
                            className="currency_determine"
                            onClick={authorization.bind(this, item)}
                          >
                            <span>
                              {item.allowance == 0 && item.coin !== 'bnb'
                                ? t("Authorization")
                                : item.status === 1
                                  ? t("Waiting_for_the_draw")
                                  : t("Kill_the_boss")}
                            </span>
                          </div>
                          <div
                            className="currency_income"
                            onClick={routeJump.bind("", item)}
                          >
                            {item.allowance === 0
                              ? t("Authorization")
                              : t("View_earnings")}
                          </div>
                        </div>
                        <div className="new_avatar">
                          <img src={item.ava_img1} alt="" />
                          <img src={item.ava_img2} alt="" />
                          <img src={item.ava_img3} alt="" />
                          <img src={item.ava_img4} alt="" />
                          <img src={item.ava_img5} alt="" />
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                <div className="explore_box">
                  <div className={lang == 'zh' ? "top" : "top_en"}>
                    {t('Brand_new_game')}<br />
                    {t('Con_con_con')}<br />
                    {t('Query_the_fall')}<br />
                    {t('Different_props_in')}
                  </div>
                  <div className={lang == 'zh' ? "bottom" : "bottom_en"}>
                    {t('Universe_Exploration')}<br />
                    {t('More_games_look_forward')}
                  </div>
                </div>
                {/* 游戏规则 */}
                <div className="game_rule">
                  <div className="game_rule_brief">
                    <p>{t("established_different_lp")}</p>
                    <p>{t("The_rules_follows")}</p>
                    <p>
                      <span>1.</span>
                      {t("Players_pay")}
                    </p>
                    <p>
                      <span>2.</span>
                      {t("Players_participate_lp")}
                    </p>
                    <p>
                      <span>3.</span>
                      {t("Among_the_players")}
                    </p>
                    <img
                      src={lang === "zh" ? rules_zh : rules_en}
                      alt=""
                      className="game_rule_title"
                    />
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane key="1">
              <div className="single_currency_copy">
                <Swiper
                  spaceBetween={50}
                  slidesPerView={3}
                  allowTouchMove={false}
                  navigation
                >
                  {/* 轮播每一项  单币副本*/}
                  {singlecurrencycopy.map((item) => {
                    return (
                      <SwiperSlide
                        key={item.id}
                        style={{display:(item.id *1===7 && showParams === "show") || item.id*1!==7  ? "block" :  "none"}}
                        className={
                          item.first == 1 ? 'one_swiper_slide_kaijiang' : item.first == -1 ? 'one_show' :
                            item.status == 0
                              ? "swiper_test"
                              : item.status == 1
                                ? "swiper-slide_kaijiang"
                                : "swiper-slide_canyu"
                        }
                      >
                        {
                          item.first == 1 ?
                            <div className={lang == 'zh' ? 'top_bg' : 'top_bg_en'}>
                              <div className="top">
                                
                                {t('More_elements')}<br />
                                {t('Gain_power')}<br />
                                {t('Explore_all')}
                              </div>
                              <div style={{ width: widthAc }} className="bottomesBox">
                                <div className="bottomes">
                                  {t('monster_profile')}:<br />
                                  {t('The_interstellar_s')}
                                </div>
                              </div>
                            </div>
                            :


                            // <div className="border_top">
                            //   {t('monster_profile')}:<br />
                            //   {item.top_text}
                            //   <img src={item.top_img} alt="" />
                            // </div>
                            <div style={{ width: widthAc }} className="borderTopBox">
                              <div className="border_topes">
                                {t('monster_profile')}:<br />
                            {item.top_text1}{item.title}
                            {item.top_text2}{item.title}
                            {item.top_text3}{item.title}
                            {item.top_text4} 
                            {item.top_text5}{item.loserMember}{item.top_text6}{item.loserMember}{item.top_text7}
                            {item.winMember}{item.top_text8}

                                <img className={lang == 'zh' ? 'b_img' : 'b_img_en'} src={item.top_img} alt="" />
                              </div>
                            </div>
                        }
                        <div className="currency_title">
                          <span>{item.title}</span>
                        </div>
                        <div className="currency_img"></div>
                        <div className="currency_info">
                          <p>
                            {t("The_participants")}
                            <span>
                              {item.peopleNum}/{item.participants}
                            </span>
                          </p>
                          <p>
                            {t("Expenditure_exploration")}
                            <span>{item.consume}</span>
                          </p>
                          <p>
                            {t("Your_balance")}
                            <span>
                              {item.balance}
                              {item.title}
                            </span>
                          </p>
                        </div>
                        <div className="currency_btn">
                          <div
                            className="currency_determine"
                            onClick={authorization.bind(this, item)}
                          >
                            <span>
                              {/* {'allowance:'+item.allowance}
                              {'status:'+item.status} */}
                              {
                                // item.coin == 'bnb'? t("join_now") :
                                item.allowance == 0 && item.coin !== 'bnb'
                                  ? t("Authorization")
                                  : item.status == 1
                                    ? t("Waiting_for_the_draw")
                                    : t("Kill_the_boss")}
                            </span>
                          </div>
                          <div
                            className="currency_income"
                            onClick={routeJump.bind("", item)}
                          >
                            {item.allowance == 0
                              ? t("Authorization")
                              : t("View_earnings")}
                          </div>
                        </div>
                        <div className="new_avatar">
                          <img src={item.ava_img1} alt="" />
                          <img src={item.ava_img2} alt="" />
                          <img src={item.ava_img3} alt="" />
                          <img src={item.ava_img4} alt="" />
                          <img src={item.ava_img5} alt="" />
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                <div className="explore_box">
                  <div className={lang == 'zh' ? "top" : "top_en"}>
                    {t('Brand_new_game')}<br />
                    {t('Con_con_con')}<br />
                    {t('Query_the_fall')}<br />
                    {t('Different_props_in')}
                  </div>
                  <div className={lang == 'zh' ? "bottom" : "bottom_en"}>
                    {t('Universe_Exploration')}<br />
                    {t('More_games_look_forward')}
                  </div>
                </div>
                {/* 游戏规则 */}
                <div className="game_rule">
                  <div className="game_rule_brief">
                    <p>{t("established_different")}</p>
                    <p>{t("The_rules_follows")}</p>
                    <p>
                      <span>1.</span>
                      {t("Players_pay")}
                    </p>
                    {/* <p>
                      <span>2.</span>
                      {t("Players_participate")}
                    </p> */}
                    <p>
                      <span>2.</span>
                      {t("Among_the_players_lp")}
                    </p>
                    <img
                      src={lang === "zh" ? rules_zh : rules_en}
                      alt=""
                      className="game_rule_title"
                    />
                  </div>
                </div>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
      {/* gif */}
      {
        isVisibleGif && 
        <div className="kill-gif">
          <div className="bg"></div>
          <img src={kill_gif} className="gif" alt="" />
        </div>
      }
    </div>
  );
};
export default Game;
