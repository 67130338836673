import home from "../assets2/top/home.png";
import homeac from "../assets2/top/homeac.png";
import game from "../assets2/top/game.png";
import gameac from "../assets2/top/gameac.png";
import mining from "../assets2/top/mining.png";
import miningac from "../assets2/top/miningac.png";
import labour from "../assets2/top/labour.png";
import labourac from "../assets2/top/labourac.png";
import property from "../assets2/top/property.png";
import propertyac from "../assets2/top/propertyac.png";

import union_ac_en from "../assets-en/top/union_ac.png";
import union_en from "../assets-en/top/union.png";
import frpnt_page_en from "../assets-en/top/frpnt_page.png";
import front_page_ac_en from "../assets-en/top/front_page_ac.png";
import game_ac_en from "../assets-en/top/game_ac.png";
import game_en from "../assets-en/top/game.png";
import mining_en from "../assets-en/top/mining.png";
import mining_ac_en from "../assets-en/top/mining_ac.png";
import props_en from "../assets-en/top/props.png";
import props_ac_en from "../assets-en/top/props_ac.png";
import dao from "../assets2/dao/dao.png";
import daoac from "../assets2/dao/daoac.png";
import daoen from "../assets2/dao/daoen.png";
import daoacen from "../assets2/dao/daoacen.png";
import game2 from "../assets/game2/game2.png";
import game2_active from "../assets/game2/game2_active.png";
import game2_cn from "../assets/game2/game2_cn.png";
import game2_cn_active from "../assets/game2/game2_cn_active.png";
import game3_cn_active from "../assets/game2/game3_cn_active.png";
import game3_cn from "../assets/game2/game3_cn.png";
import game3 from "../assets/game2/game3.png";
import game3_active from "../assets/game2/game3_active.png";

const lang = localStorage.getItem("lang") || "en";

const menuszh = [
  {
    key: "/",
    title: "首页",
    component: "Home",
    img: home,
    imgactive: homeac,
  },
  {
    key: "/game/:showParams?",
    title: "游戏",
    component: "Game",
    img: game,
    imgactive: gameac,
  },
  // {
  //   key: "/game2",
  //   title: "时空之门(旧)",
  //   component: "Game2",
  //   img: game2_cn,
  //   imgactive: game2_cn_active,
  // },
  {
    key: "/game3",
    title: "星际夺宝",
    component: "Game3",
    img: game3_cn,
    imgactive: game3_cn_active,
  },
  {
    key: "https://bridge.gwcswap.com/game",
    link:true,
    title: "预言家",
    component: "Property",
    img: mining,
    imgactive: miningac,
  },
  {
    key: "/labourunion",
    title: "工会",
    component: "Labourunion",
    img: labour,
    imgactive: labourac,
  },
  {
    key: "/property",
    title: "道具",
    component: "Property",
    img: property,
    imgactive: propertyac,
  },
  {
    key: "/dao",
    title: "Dao",
    component: "Dao",
    img: dao,
    imgactive: daoac,
  },
];

const menusen = [
  {
    key: "/",
    title: "Home",
    component: "Home",
    img: props_ac_en,
    imgactive: props_en,
  },
  {
    key: "/game/:showParams?",
    title: "Game",
    component: "Game",
    img: game_ac_en,
    imgactive: game_en,
  },
  // {
  //   key: "/game2",
  //   title: "Game Gate(old)",
  //   component: "Game2",
  //   img: game2,
  //   imgactive: game2_active,
  // },
  {
    key: "/game3",
    title: "Star Treasure",
    component: "Game3",
    img: game3,
    imgactive: game3_active,
  },
  {
    key: "/mining",
    title: "Mining",
    component: "Property",
    img: mining_ac_en,
    imgactive: mining_en,
  },
  {
    key: "/labourunion",
    title: "Union",
    component: "Labourunion",
    img: union_ac_en,
    imgactive: union_en,
  },
  {
    key: "/property",
    title: "Front Page",
    component: "Property",
    img: front_page_ac_en,
    imgactive: frpnt_page_en,
  },
  {
    key: "/dao",
    title: "Dao",
    component: "Dao",
    img: daoen,
    imgactive: daoacen,
  },
];

const menus = {
  // 菜单相关路由
  menus: lang === "zh" ? menuszh : menusen,
  // 非菜单路由
  others: [
    {
      key: "/game/info/:title",
      component: "Gameinfo",
    },
    {
      key: "/labourunion/info/:id",
      component: "LabourunionInfo",
    },
    {
      key: "/mining/info/:title",
      component: "Mininginfo",
    },
    {
      key: "/dao/info/:id",
      component: "Daoinfo",
    },
    {
      key: "/game2/info/:id",
      component: "Game2Info",
    },
    {
      key: "/game2/incomerecord/:id",
      component: "Game2Incomerecord",
    },
    {
      key: "/game3/info/:id",
      component: "Game3Info",
    },
    {
      key: "/game3/incomerecord/:id",
      component: "Game3Incomerecord",
    },
    {
      key: "/createlabourunion/:id?",
      component: "CreateLabourunion",
    },
    {
      key: "/withdraw",
      component: "Withdraw",
    }, {
      key: "/turnover",
      component: "Turnover",
    }, {
      key: "/news",
      component: "News",
    },
  ],
};

export default menus;
