import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import "./home.less";
import notice_img from "../../assets/notice.png";
import banner from "../../assets2/new-home/banner.jpg";
import section1_en from "../../assets2/new-home/section1-en.jpg";
import section1_zh from "../../assets2/new-home/section1-zh.jpg";
import section2 from "../../assets2/new-home/section2.jpg";
import section3 from "../../assets2/new-home/section3.jpg";
import section4 from "../../assets2/new-home/section4.jpg";
import section4Right from "../../assets2/new-home/section4-right.png";


// 语言
const lang = localStorage.getItem("lang") || "en";

const Home = (props) => {
  const { t } = useTranslation();
  // 屏幕宽度
  const [width, setWidth] = useState(0);
  // 探索副本
  const exploreCopy = () => {
    props.history.push("/game");
  };
  // 参观矿池
  const miningPool = () => {
    props.history.push("/mining");
  };
  useEffect(() => {
    getWidth();
  }, []);
  // 获取屏幕宽度
  const getWidth = () => {
    let width = document.body.clientWidth;
    setWidth(width);
  };
  // E notice
  return (
    <div className="home-new">
        {/* section-banner */}
       <section className="section-banner">
         <img src={banner} width="100%" />
       </section>
       {/* section-notice */}
       <section className="section-notice">
        <div className="wrapper">
         <img
           src={notice_img}
           className="notice-img" />
           <Link to="/news" className="notice-swiper ellipsis">{t('news.homeTitle')}</Link>
        </div>
        </section>
         {/* section-about */}
       <section className="section-about">
        <div className="con-box">
         <div className="title">What is<br/>interstellar treasure hunt</div>
         <div className="content">
          <p>{t('home.about.content1')}</p>
          <p>{t('home.about.content2')}</p><br/>
          <p>{t('home.about.content3')}</p>
          <p>{t('home.about.content4')}</p><br/>
          <p>{t('home.about.content5')}</p>
          <p>{t('home.about.content6')}</p>
         </div>
        </div>
         <img
           src={lang === "zh" ? section1_zh : section1_en}
           alt=""
           width="100%" />
       </section>
       {/* section-introduce */}
       <section className="section-introduce">
        <ul className="list">
          <li className="item">
            <h5 className="title">{t('home.introduce.item1.title')}</h5>
            <div className="content">
              <p>{t('home.introduce.item1.content1')}</p>
              <p>{t('home.introduce.item1.content2')}</p> <br></br> <br/>
            </div>
          </li>
          <li className="item">
            <h5 className="title">{t('home.introduce.item2.title')}</h5>
            <div className="content">
              <p>{t('home.introduce.item2.content1')}</p>
              <p>{t('home.introduce.item2.content2')}</p>
            </div>
          </li>
          <li className="item">
            <h5 className="title">{t('home.introduce.item3.title')}</h5>
            <div className="content">
              <p>{t('home.introduce.item3.content1')}</p>
            </div>
          </li>
        </ul>
       </section>
       {/* section-person */}
       <section className="section-person">
        <div className="title"><span>R</span>ich and diverse roles</div>
        <div className="con">
        <img src={section2} alt="" width="100%" />
        </div>
       </section>
       {/* section-model */}
       <section className="section-model">
        <div className="title">{t('home.model.title')}</div>
        <div className="tips">{t('home.model.tips')}</div>
        <div className="content">{t('home.model.content')}</div>
        <div className="picture">
        <img src={section3} alt="" width="100%" />
        </div>
       <ul className="list">
        <li className="item">
          <p><span>{t('home.model.item1.content1')}</span></p>
          <p className="no">{t('home.model.item1.no')}</p>
        </li>
        <li className="item">
          <p><span>{t('home.model.item2.content1')}</span></p>
          <p className="no">{t('home.model.item2.no')}</p>
        </li>
        <li className="item">
          <p><span>{t('home.model.item3.content1')}</span></p>
          <p className="no">{t('home.model.item3.no')}</p>
        </li>
       </ul>
          </section>
          {/* section-last */}
          <section className="section-last">
        <img src={section4} alt="" className="bg" />
        <div className="con-box">
          <div className="border-box">
            GWC<br/>
          {t('home.last.content1')}<br/>
          {t('home.last.content2')}<br/>
          {t('home.last.content3')}<br/>
          {t('home.last.content4')}
          </div>
      </div>
      <img src={section4Right} alt="" className="section4-right" />
        
          {/* 底部 */}
          <div className="footer">
            <div>
              <a
                href="https://twitter.com/gwc_global?s=21&t=Nkyo6HAFjGx59vbujrKLLw"
                className="twitter"
                target="_block"
              ></a>
            </div>
            <div>
              <a
                href="https://t.me/GWC_Global_Community"
                className="ter"
                target="_block"
              ></a>
            </div>
            <div>
              <a href="#" className="youxi"></a>
            </div>
            <div>
              <a href="#" className="medium"></a>
            </div>
            <div>
              <a href="#" className="xin"></a>
            </div>
          </div>
          </section>

       </div>
  );
};
export default Home;
