import React, { useState, useEffect, useRef } from "react";
import { Button, Menu, Drawer, Modal, message, Radio, Space } from "antd";
import { useHistory, Link } from "react-router-dom";
import routesConfig from "../../router/config";

import logoTitle from "../../assets1/logo1.png";
import yuyan from "../../assets/yuyan.png";

import fuzhi from "../../assets/home/fuzhi.png";
import shangchuan from "../../assets/home/shangchuan-2.png";
import purse from "../../assets2/top/purse.png";
import link_wallet_en from "../../assets-en/top/link_wallet_en.png";
import link_wallet from "../../assets2/game/info_box_bg.png";
import GWCAuditReport from "../../assets2/GWC-Audit-Report-092821(1).pdf";

import { MenuOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";

import "./topbar.less";

import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import useWallet from "use-wallet";
import { AppConfig } from "../../config";
import { globalParam } from "../../chain/config";
import FormItemLabel from "antd/lib/form/FormItemLabel";
import { useTranslation } from "react-i18next";

const Topbar = (props) => {
  const { t } = useTranslation();
  let history = useHistory();
  const [visible, setVisible] = useState(false);
  // 移动端路由点击了谁
  const [key, setKey] = useState("");
  // 是否显示未连接钱包弹窗
  const [isConnectWallet, setIsConnectWallet] = useState(false);
  // 是否连接了钱包
  const [isWallet, setIsWallet] = useState(false);
  // 是否显示已连接钱包弹窗
  const [connectedWallet, setConnectedWallet] = useState(false);
  // 移动端显示语言选择
  const [isModalVisible, setIsModalVisible] = useState(false);
  // 显示的语言
  const [language, setLanguage] = useState("");
  // 语言
  const [value, setValue] = useState(localStorage.getItem("lang") || "en");
  // 地址
  const showDrawer = (e) => {
    window.event ? (window.event.cancelBubble = true) : e.stopPropagation();
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  let { status, connect, account: addressdz } = useWallet();

  console.log("status:", status, "account:", addressdz);
  useEffect(() => {
    document.onclick = () => {
      setVisible(false);
    };
    setKey(props.props.location.pathname);
  });

  useEffect(() => {
    if (status === "connected") {
      AppConfig.addr = addressdz;
      globalParam.user = addressdz;
      setIsWallet(true);
    }
  }, [status]);

  useEffect(async () => {
    await connect();
    setKey(props.props.location.pathname);
    if (value === "en") {
      setLanguage("En");
    } else if (value === "zh") {
      setLanguage("中文");
    }
  }, []);

  const onNoGuan = (e) => {
    window.event ? (window.event.cancelBubble = true) : e.stopPropagation();
  };

  // 切换菜单路由
  const menuClick = ({ key }) => {
    // console.error(key.search('http'))
    if(key.search('http') === 0) {
      window.open(key)
    } else {
      history.push(key);
    }
  };

  // 移动端路由点击
  const setLinkKey = (key) => {
    setKey(key);
    setVisible(false);
  };

  // 关闭弹窗
  const handleConnectWallet = () => {
    setIsConnectWallet(false);
  };
  // 开启弹框
  const onClickHandle = () => {
    console.log("conneting.....");
    setIsConnectWallet(true);
    setVisible(false);
  };

  // 连接Metamask
  const connectionMetamask = async () => {
    console.log("connet to metamaske...", connect);
    await connect();
    setIsConnectWallet(false);
  };

  // 连接Metamask
  const onClickHandleQRcode = async () => {
    await connect("walletconnect");
    setIsConnectWallet(false);
  };

  // 开启已已连接钱包弹窗
  const okConnectedWallet = async () => {
    setConnectedWallet(true);
    setVisible(false);
  };

  // 关闭已连接钱包弹窗
  const handleConnectedWallet = () => {
    setConnectedWallet(false);
  };

  // 复制成功操作
  const handleCopy = () => {
    message.success("复制成功");
  };

  // 改变连接
  const changeHandle = () => {
    setConnectedWallet(false);
    setIsConnectWallet(true);
  };
  const handleCancelWeb = () => {
    setIsModalVisible(false);
  };

  // 已连接钱包结构
  const address = (
    <div className="address" onClick={okConnectedWallet}>
      <div className="personal_address">
        {addressdz && addressdz.slice(0, 5)}...
        {addressdz && addressdz.slice(-5)}
        {/* <span>Sign Out</span> */}
      </div>
    </div>
  );
  // 未连接钱包结构
  const weiaddress = (
    <div className="weiaddress" onClick={onClickHandle}>
      Connect wallet
    </div>
  );

  // 切换语言
  const languageE = (
    <div
      className="personal_img"
      onClick={() => {
        setIsModalVisible(true);
        setVisible(false);
      }}
    >
      <img src={yuyan} alt="" />
      <span>{language}</span>
    </div>
  );
  // 移动端设置语言
  const onChangeLang = ({ target: { value } }) => {
    setValue(value);
    localStorage.setItem("lang", value);
    console.log("value===", value === "zh");
    if (value === "en") {
      setLanguage("En");
    } else if (value === "zh") {
      setLanguage("中文");
    }
    window.location.reload();
  };
  return (
    <div className={key == '/' ? "topbarhome" : 'topbar'}>
      <div className="top_title">
        <img src={logoTitle} alt="" />
        <div className="top_title_text">GOD OF WEALTH</div>
      </div>
      <div className="top_link">
        <Menu
          mode="horizontal"
          onClick={menuClick}
          style={{ height: "100%", minWidth: "3.8rem" }}
          className="menu_list"
          selectable
          selectedKeys={props.props.location.pathname}
          defaultSelectedKeys={props.props.location.pathname}
        >
          {routesConfig.menus.map((item) => {
            return <Menu.Item key={item.key}>{item.title}</Menu.Item>;
          })}
        </Menu>
      </div>
      <div className="top_personal">
        {isWallet ? address : weiaddress}
        {languageE}
      </div>

      {/* 移动端显示的内容 */}
      <div className="top_menu" onClick={showDrawer}>
        <MenuOutlined style={{ fontSize: "0.3rem" }} />
      </div>

      {/* 移动端右侧栏 */}
      <Drawer
        onClick={onNoGuan}
        className="tanchu"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        width="2.5rem"
      >
        <div className="right_link">
          {routesConfig.menus.map((item) => {
            return (
                item.link ?(
                    <a href={item.key} target="_black"><img
                        src={key === item.key ? item.imgactive : item.img}
                        alt=""
                        className="key_active"
                    /></a>
                    ):
                    (<Link
                to={item.key}
                key={item.key}
                onClick={setLinkKey.bind("", item.key)}
              >
                <img
                  src={key === item.key ? item.imgactive : item.img}
                  alt=""
                  className="key_active"
                />
              </Link>)
            );
          })}

          {isWallet ? address : weiaddress}
          {languageE}
          <a href={GWCAuditReport} target="_black" className="audit">
            {t("Audit_Report")}
          </a>
        </div>
      </Drawer>

      {/* 未连接钱包弹窗 */}
      <Modal
        visible={isConnectWallet}
        onCancel={handleConnectWallet}
        footer={null}
        closable={false}
        width={"100%"}
        className="wallet_modal_wei"
        maskStyle={{
          backgroundImage:
            "url(" + require("../../assets2/game/moadle_bg.png").default + ")",
          backgroundSize: "100% 100%",
        }}
      >
        <div className="connect_wallet_top">
          <div className="wallet_top">
            <div className="wallet_title">
              <img src={value === "zh" ? link_wallet : link_wallet_en} alt="" />
            </div>
            {/* <div className="wallet_gb" onClick={handleConnectWallet}>
              <CloseCircleOutlined />
            </div> */}
          </div>
          <div className="wallet_btn">
            <div className="wallet_lianjie">
              <div className="wallet_metamask">
                <div className="wallet_metamask_img">
                  {/* <img src={namebg} alt="" className="name_bg" /> */}
                  <img src={purse} alt="" className="metamask_img" />
                </div>
                <div className="lianjie_title">Metamask</div>
                <div
                  className="wallet_metamask_btn"
                  onClick={connectionMetamask}
                >
                  {t("connect")}
                </div>
              </div>
              <div className="wallet_conncet">
                <div className="wallet_metamask_img">
                  {/* <img src={namebg} alt="" className="name_bg" /> */}
                  <img src={purse} alt="" className="metamask_img" />
                </div>
                <div className="lianjie_title">WalletConncet</div>
                <div
                  className="wallet_metamask_btn"
                  onClick={onClickHandleQRcode}
                >
                  {t("connect")}
                </div>
              </div>
            </div>
            <div className="quxiao" onClick={handleConnectWallet}>
              {t("cancel")}
            </div>
          </div>
        </div>
      </Modal>

      {/* 已连接了钱包 */}
      <Modal
        visible={connectedWallet}
        onCancel={handleConnectedWallet}
        footer={null}
        closable={false}
        width={400}
        centered={true}
      >
        <div className="connected_wallet_yi">
          {/* <div className="connected_top">
            <div className="connected_title">账号</div>
            <div className="connected_gb" onClick={handleConnectedWallet}>
              <CloseCircleOutlined />
            </div>
          </div> */}
          <div className="connected_btn">
            <div className="connected_con">
              <div className="con_title">
                {t("connect_to_metamask")}
                <span onClick={changeHandle}>{t("change")}</span>
              </div>
              <div className="con_address">
                <div className="con_icon">
                  <img src="" alt="" />
                </div>
                <span>
                  {addressdz && addressdz.slice(0, 5)}...
                  {addressdz && addressdz.slice(-5)}
                </span>
              </div>
              <div className="con_link">
                <CopyToClipboard text={addressdz} onCopy={handleCopy}>
                  <div className="link_copy">
                    <img src={fuzhi} alt="" />
                    <span>{t("copy_address")}</span>
                  </div>
                </CopyToClipboard>

                <a
                  href={"https://rinkeby.etherscan.io/address/" + addressdz}
                  className="tiaozhuan"
                  target="_target"
                >
                  <img src={shangchuan} alt="" />
                  <span>{t("view_on_bsccan")}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* 移动端选择语言 */}
      <Modal
        className="lang_modal"
        footer={null}
        visible={isModalVisible}
        onCancel={handleCancelWeb}
        centered={true}
      >
        <Radio.Group onChange={onChangeLang} value={value}>
          <Space direction="vertical">
            <Radio value={"zh"}>中文</Radio>
            <Radio value={"en"}>English</Radio>
          </Space>
        </Radio.Group>
      </Modal>
    </div>
  );
};
export default Topbar;
