import tou1 from '../../assets1/property/tou1.png';
import tou2 from '../../assets1/property/tou2.png';
import tou3 from '../../assets1/property/tou3.png';
import tou4 from '../../assets1/property/tou4.png';
import tou5 from '../../assets1/property/tou5.png';

import two_tou1 from '../../assets1/property/two_tou1.png';
import two_tou2 from '../../assets1/property/two_tou2.png';
import two_tou3 from '../../assets1/property/two_tou3.png';
import two_tou4 from '../../assets1/property/two_tou4.png';
import two_tou5 from '../../assets1/property/two_tou5.png';

import three_tou1 from '../../assets1/property/three_tou1.png';
import three_tou2 from '../../assets1/property/three_tou2.png';
import three_tou3 from '../../assets1/property/three_tou3.png';
import three_tou4 from '../../assets1/property/three_tou4.png';
import three_tou5 from '../../assets1/property/three_tou5.png';

import four_tou1 from '../../assets1/property/four_tou1.png';
import four_tou2 from '../../assets1/property/four_tou2.png';
import four_tou3 from '../../assets1/property/four_tou3.png';
import four_tou4 from '../../assets1/property/four_tou4.png';
import four_tou5 from '../../assets1/property/four_tou5.png';

import five_tou1 from '../../assets1/property/five_tou1.png';
import five_tou2 from '../../assets1/property/five_tou2.png';
import five_tou3 from '../../assets1/property/five_tou3.png';
import five_tou4 from '../../assets1/property/five_tou4.png';
import five_tou5 from '../../assets1/property/five_tou5.png';

export const ImgObj = {
    tou1:tou1,
    tou2:tou2,
    tou3:tou3,
    tou4:tou4,
    tou5:tou5,
    two_tou1:two_tou1,
    two_tou2:two_tou2,
    two_tou3:two_tou3,
    two_tou4:two_tou4,
    two_tou5:two_tou5,
    three_tou1:three_tou1,
    three_tou2:three_tou2,
    three_tou3:three_tou3,
    three_tou4:three_tou4,
    three_tou5:three_tou5,
    four_tou1:four_tou1,
    four_tou2:four_tou2,
    four_tou3:four_tou3,
    four_tou4:four_tou4,
    four_tou5:four_tou5,
    five_tou1:five_tou1,
    five_tou2:five_tou2,
    five_tou3:five_tou3,
    five_tou4:five_tou4,
    five_tou5:five_tou5,
}