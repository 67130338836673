import React, {useState, useEffect, useCallback} from "react";
import {useTranslation} from "react-i18next";
import "./mining.less";
import god_of from "../../assets2/game/god_of.png";
import lp from "../../assets2/mining/lp.png";
import sc from "../../assets2/mining/sc.png";
import lp_ac from "../../assets2/mining/lp_ac.png";
import sc_ac from "../../assets2/mining/sc_ac.png";
import sc_en_ac from "../../assets-en/mining/sc_en_ac.png";
import sc_en from "../../assets-en/mining/sc_en.png";
import lp_en_ac from "../../assets-en/mining/lp_en_ac.png";
import lp_en from "../../assets-en/mining/lp_en.png";
import rules_en from "../../assets-en/rules_en.png";
import rules_zh from "../../assets-en/rules_zh.png";
import eth_usdtlp from "../../assets2/mining/eth-usdtlp.png";
import btc_usdtlp from "../../assets2/mining/btc-usdtlp.png";
import fil_usdtlp from "../../assets2/mining/fil-usdtlp.png";
import husd_usdtlp from "../../assets2/mining/husd-usdt.png";
import ltc_usdt from "../../assets2/mining/ltc-usdt.png";
import gwc_usdt from "../../assets2/mining/gwc-usdt.png";
import ht_usdt from "../../assets2/mining/ht-usdt.png";
import gwc_husd from "../../assets2/mining/gwc-husd.png";
import gwc_ht from "../../assets2/mining/gwc-ht.png";
import bnb_usdt from "../../assets2/mining/bnb-usdt.png";
import gwc_bnb from "../../assets2/mining/gwc-bnb.png";
import gwc_btc from "../../assets2/mining/gwc-btc.png";
import gwc_eth from "../../assets2/mining/gwc-eth.png";

import {Tabs} from "antd";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Navigation} from "swiper";
import {address, globalParam, initContracts} from "../../chain/config";
import BigNumber from "bignumber.js";
import useWallet from "use-wallet";

const {TabPane} = Tabs;

SwiperCore.use(Navigation);


// LP挖矿模拟数据
const LPminings = [
    {
        id: 0,
        title: "ETH-USDT LP",
        pledge: "0",
        APY: "0",
        coin: "ethlp",
        pool: "ethlppool",
        currImg: eth_usdtlp,
    },
    {
        id: 1,
        title: "BTC-USDT LP",
        pledge: "0",
        APY: "0",
        coin: "btclp",
        pool: "btclppool",
        currImg: btc_usdtlp,
    },
    {
        id: 2,
        title: "FIL-USDT LP",
        pledge: "0",
        APY: "0",
        coin: "fillp",
        pool: "fillppool",
        currImg: fil_usdtlp,
    },
    {
        id: 3,
        title: "GWC-BTC",
        pledge: "0",
        APY: "0",
        coin: "gwcbtclp",
        pool: "gwcbtclppool",
        currImg: gwc_btc,
    },
    {
        id: 4,
        title: "LTC-USDT",
        pledge: "0",
        APY: "0",
        coin: "ltclp",
        pool: "ltclppool",
        currImg: ltc_usdt,
    },
    {
        id: 5,
        title: "BNB-USDT",
        pledge: "0",
        APY: "0",
        coin: "bnbusdtlp",
        pool: "bnbusdtlppool",
        currImg: bnb_usdt,
    },
    {
        id: 6,
        title: "GWC-USDT",
        pledge: "0",
        APY: "0",
        coin: "gwclp",
        pool: "gwclppool",
        currImg: gwc_usdt,
    },
    {
        id: 7,
        title: "GWC-ETH",
        pledge: "0",
        APY: "0",
        coin: "gwceth",
        pool: "gwcethlppool",
        currImg: gwc_eth,
    },
    {
        id: 8,
        title: "GWC-BNB",
        pledge: "0",
        APY: "0",
        coin: "gwcbnb",
        pool: "gwcbnblppool",
        currImg: gwc_bnb,
    },
];

const lang = localStorage.getItem("lang") || "en";

const Mining = (props) => {
    const {t} = useTranslation();
    const {account} = useWallet();

    // const [singlecurrencymining, setSing] = useState(singlecurrencyminings);
    const [LPmining, setLPmining] = useState(LPminings);
    // 选中的tablan
    const [activeTab, setActiveTab] = useState("2");
    // 跳转页面
    const goMininginfo = (item) => {
        props.history.push(`/mining/info/${item.title}`);
        sessionStorage.setItem("MiningList", JSON.stringify(item));
    };

    const getInfo = useCallback(async () => {
        if (!account) return;
        // singlecurrencymining[0].aa = new Date().getTime();
        LPmining[0].aa = new Date().getTime();
        //gwc - usdt
        let gwcBalance = await initContracts("gwc")
            .contract.methods.balanceOf(address["gwclp"].address)
            .call();
        console.log("gwc balance in gwc-usdt lp:", gwcBalance);
        let usdtBalance = await initContracts("usdt")
            .contract.methods.balanceOf(address["gwclp"].address)
            .call();
        console.log("usdt balance in gwc-usdt lp:", usdtBalance);
        let gwcPrice = new BigNumber(1);
        if (usdtBalance != 0 && gwcBalance != 0) {
            gwcPrice = new BigNumber(usdtBalance)
                .div(new BigNumber(10).pow(address["usdt"].decimal))
                .div(
                    new BigNumber(gwcBalance).div(
                        new BigNumber(10).pow(address["gwc"].decimal)
                    )
                );
        }
        console.log('gwc price by pancage', gwcPrice.toString())

        const b = LPmining.map(async (item) => {
            //apy计算特殊处理 sgs-husd,sgs-nt

            if (item.coin === "gwcbnb") {
                //gwc-ht
                console.log("gwcbnb coin:", item.coin);
                // console.log("itemiiii。。。。。。。。:",address[item.coin.slice(0, -2)])
                console.log("gwcbnb :", address[item.coin].decimal);
                // console.log("gwcbnb :", address[item.coin]);
                console.log("gwcbnb :", address[item.coin].decimal);

                let totalSupply = await initContracts("gwcbnblppool")
                    .contract.methods.totalSupply()
                    .call();

                let historytotal = await initContracts("gwcbnblppool")
                    .contract.methods.historyTotal()
                    .call();
                console.log('total su:', totalSupply, 'history:', historytotal)
                item.pledge = new BigNumber(historytotal)
                    .div(new BigNumber(10).pow(18))
                    .toFixed(globalParam.showDecimal);

                if (totalSupply == 0) {
                    item.APY = 0;
                    console.log('apy = 0,return')
                    return;
                }

                console.log('item.pledge:', item.pledge, 'item.apy:', item.APY)
                let allLpTotal = await initContracts("gwcbnblp")
                    .contract.methods.totalSupply()
                    .call();

                console.log('alllpTotal:', allLpTotal)
                let diff = allLpTotal / totalSupply; //总生成的lp是质押到池子中的多少倍
                let tokenBalance = await initContracts("wbnb")
                    .contract.methods.balanceOf(address["bnblp"].address)
                    .call();
                let usdtBalance = await initContracts("usdt")
                    .contract.methods.balanceOf(address["bnblp"].address)
                    .call();
                let tokenPrice = new BigNumber(usdtBalance == 0 ? 1 : usdtBalance)
                    .div(new BigNumber(10).pow(address["usdt"].decimal))
                    .div(
                        new BigNumber(tokenBalance).div(
                            new BigNumber(10).pow(18) //todo ht应该是18吧
                        )
                    );

                let rewardRate = await initContracts("gwcbnblppool")
                    .contract.methods.rewardRate()
                    .call();

                let apy = new BigNumber(rewardRate)
                    .times(365 * 24 * 86400)
                    .times(gwcPrice)
                    .times(100)
                    .div(2)
                    .div(1e18)
                    .div(
                        new BigNumber(usdtBalance == 0 ? 1 : usdtBalance).div(
                            new BigNumber(10).pow(address["usdt"].decimal)
                        )
                    )
                    .div(diff)
                    .toFixed();
                item.APY = apy;
            } else if (item.coin.slice(-2) === "lp") {
                console.log('lp pool:', item.coin)
                // console.log("itemiiii:",item)
                // console.log('sllllll:',address[item.coin.slice(0, -2)].decimal)
                // console.log("itemiiii:",address[item.coin])
                // console.log('coin......aa:',item.coin)
                let totalSupply = await initContracts(item.pool)
                    .contract.methods.totalSupply()
                    .call();

                let historytotal = await initContracts(item.pool)
                    .contract.methods.historyTotal()
                    .call();

                console.log('toto su:', totalSupply, 'histro:', historytotal)

                console.log('iiiiiiii:', address[item.coin]?.decimal, item.coin)
                item.pledge = new BigNumber(historytotal)
                    .div(new BigNumber(10).pow(address[item.coin].decimal))
                    .toFixed(globalParam.showDecimal);

                if (totalSupply == 0) {
                    item.APY = 0;
                    return;
                }
                let allLpTotal;
                allLpTotal = await initContracts(item.coin)
                    .contract.methods.totalSupply()
                    .call();

                let diff = allLpTotal / totalSupply;
                let tokenBalance = await initContracts(item.coin.slice(0, -2))
                    .contract.methods.balanceOf(address[item.coin].address)
                    .call();
                let usdtBalance = await initContracts("usdt")
                    .contract.methods.balanceOf(address[item.coin].address)
                    .call();
                let tokenPrice = new BigNumber(usdtBalance == 0 ? 1 : usdtBalance)
                    .div(new BigNumber(10).pow(address["usdt"].decimal))
                    .div(
                        new BigNumber(tokenBalance).div(
                            new BigNumber(10).pow(address[item.coin.slice(0, -2)].decimal)
                        )
                    );
                let rewardRate = await initContracts(item.pool)
                    .contract.methods.rewardRate()
                    .call();

                let apy = new BigNumber(rewardRate)
                    .times(365 * 24 * 86400)
                    .times(gwcPrice)
                    .times(100)
                    .div(2)
                    .div(1e18)
                    .div(
                        new BigNumber(usdtBalance == 0 ? 1 : usdtBalance).div(
                            new BigNumber(10).pow(address["usdt"].decimal)
                        )
                    )
                    .div(diff)
                    .toFixed();
                item.APY = apy;
            } else {
                console.log("other pool coin:", item.coin);
                //gwc-ht
                // console.log("itemiiii。。。。。。。。:",address[item.coin.slice(0, -2)])
                console.log("gwcbnb :", address[item.coin].decimal);
                // console.log("gwcbnb :", address[item.coin]);
                console.log("gwcbnb :", address[item.coin].decimal);

                let totalSupply = await initContracts("gwceth")
                    .contract.methods.totalSupply()
                    .call();

                let historytotal = await initContracts("gwcethlppool")
                    .contract.methods.historyTotal()
                    .call();

                console.log('total su:', totalSupply, 'history:', historytotal)
                item.pledge = new BigNumber(historytotal)
                    .div(new BigNumber(10).pow(18))
                    .toFixed(globalParam.showDecimal);

                if (totalSupply == 0) {
                    item.APY = 0;
                    console.log('apy = 0,return')
                    return;
                }

                console.log('item.pledge:', item.pledge, 'item.apy:', item.APY)
                let allLpTotal = await initContracts("gwceth")
                    .contract.methods.totalSupply()
                    .call();

                console.log('alllpTotal:', allLpTotal)
                let diff = allLpTotal / totalSupply; //总生成的lp是质押到池子中的多少倍
                let tokenBalance = await initContracts("eth")
                    .contract.methods.balanceOf(address["gwceth"].address)
                    .call();
                let usdtBalance = await initContracts("usdt")
                    .contract.methods.balanceOf(address["bnblp"].address)
                    .call();
                // let tokenPrice = new BigNumber(usdtBalance == 0 ? 1 : usdtBalance)
                //     .div(new BigNumber(10).pow(address["usdt"].decimal))
                //     .div(
                //         new BigNumber(tokenBalance).div(
                //             new BigNumber(10).pow(18) //todo ht应该是18吧
                //         )
                //     );

                let rewardRate = await initContracts("gwcethlppool")
                    .contract.methods.rewardRate()
                    .call();

                let apy = new BigNumber(rewardRate)
                    .times(365 * 24 * 86400)
                    .times(gwcPrice)
                    .times(100)
                    .div(2)
                    .div(1e18)
                    .div(
                        new BigNumber(usdtBalance == 0 ? 1 : usdtBalance).div(
                            new BigNumber(10).pow(address["usdt"].decimal)
                        )
                    )
                    .div(diff)
                    .toFixed();
                item.APY = apy;
            }


            return item;
        });

        setLPmining([...LPmining]);
    }, [LPmining, account]);

    useEffect(() => {
        setTimeout(() => getInfo(), 1000);
        const timer = setInterval(() => getInfo(), 5e3);
        return () => {
            clearInterval(timer);
        };
    }, [account]);

    //初始化调用

    return (
        <div className="mining">
            {/* 背景 */}
            {/* <img src={miningbg} alt="" className="miningbg_bg" /> */}

            <div className="mining_top">
                <div className="top_img">
                    <img src={god_of} alt=""/>
                </div>
                <div className="tab_title">
                    <div
                        className="tab_lp"
                        onClick={() => {
                            setActiveTab("2");
                        }}
                    >
                        <img
                            src={
                                lang === "zh"
                                    ? activeTab === "2"
                                        ? lp_ac
                                        : lp
                                    : activeTab === "2"
                                        ? lp_en_ac
                                        : lp_en
                            }
                            alt=""
                            className={activeTab !== "2" ? "tab_img" : ""}
                        />
                    </div>
                    {/* <div
            className="tab_sc"
            onClick={() => {
              setActiveTab("1");
            }}
          >
            <img
              src={
                lang === "zh"
                  ? activeTab === "1"
                    ? sc_ac
                    : sc
                  : activeTab === "1"
                  ? sc_en_ac
                  : sc_en
              }
              alt=""
              className={activeTab !== "1" ? "tab_img" : ""}
            />
          </div> */}
                </div>
            </div>

            {/* 内容 */}
            <div className="miningbg_con">
                <div className="mining_tab">
                    <Tabs activeKey={activeTab}>
                        <TabPane tab="" key="1">
                            <div className="single_currency_mining">
                                <Swiper
                                    spaceBetween={20}
                                    slidesPerView={3}
                                    slidesPerColumn={2}
                                    allowTouchMove={false}
                                    navigation
                                >
                                    {/*{singlecurrencymining.map((item) => {*/}
                                    {/*  return (*/}
                                    {/*    <SwiperSlide key={item.id} className="single_item">*/}
                                    {/*      <div className="single_item_box">*/}
                                    {/*        <div className="single_title"></div>*/}
                                    {/*        <div className="single_con">*/}
                                    {/*          {t("Pledge")}*/}
                                    {/*          {item.title}*/}
                                    {/*          {t("get")}*/}
                                    {/*        </div>*/}
                                    {/*        <div className="single_gwc">GWC</div>*/}
                                    {/*        <div*/}
                                    {/*          className="xuanze"*/}
                                    {/*          onClick={goMininginfo.bind("", item)}*/}
                                    {/*        >*/}
                                    {/*          {t("choose")}*/}
                                    {/*        </div>*/}
                                    {/*        <div className="single_info">*/}
                                    {/*          <p>*/}
                                    {/*            {t("Cumulative_pledge")}*/}
                                    {/*            <span>{`${item.pledge}`}</span>*/}
                                    {/*          </p>*/}
                                    {/*          <p>*/}
                                    {/*            APY*/}
                                    {/*            <span>*/}
                                    {/*              {item.APY ? (item.APY - 0).toFixed(2) : "0.00"}%*/}
                                    {/*            </span>*/}
                                    {/*          </p>*/}
                                    {/*        </div>*/}
                                    {/*      </div>*/}
                                    {/*    </SwiperSlide>*/}
                                    {/*  );*/}
                                    {/*})}*/}
                                </Swiper>
                                {/* <div className="single_currency_mining_brief">
                  <p>{t("established_different")}</p>
                  <img
                    src={lang === "zh" ? rules_zh : rules_en}
                    alt=""
                    className="mining_brief_title"
                  />
                </div> */}
                            </div>
                        </TabPane>
                        <TabPane tab="" key="2">
                            <div className="LP_mining">
                                <Swiper
                                    spaceBetween={20}
                                    slidesPerView={3}
                                    slidesPerColumn={2}
                                    allowTouchMove={false}
                                    navigation
                                >
                                    {LPmining.map((item) => {
                                        return (
                                            <SwiperSlide key={item.id} className="single_item">
                                                <div className="single_item_box">
                                                    <div className="single_title">
                                                        <img src={item.currImg} alt=""/>
                                                    </div>
                                                    <div className="single_con">质押{item.title}获得</div>
                                                    <div className="single_gwc">GWC</div>
                                                    <div
                                                        className="xuanze"
                                                        onClick={goMininginfo.bind("", item)}
                                                    >
                                                        选择
                                                    </div>
                                                    <div className="single_info">
                                                        <p>
                                                            累计质押<span>{item.pledge}</span>
                                                        </p>
                                                        <p>
                                                            APY<span>{(item.APY - 0).toFixed(2)} %</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                                {/* <div className="single_currency_mining_brief">
                  <p>{t("established_different")}</p>
                  <img
                    src={lang === "zh" ? rules_zh : rules_en}
                    alt=""
                    className="mining_brief_title"
                  />
                </div> */}
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};
export default Mining;
