export const AppConfig = {
  // chainId: 56,
  chainId: 2999,
  // chainId:11155111,
  addr: "",
  rpcUrl: {
    1: "https://mainnet.infura.io/v3/a17d484065334e38bd8b6475ca266c88",
    42: "https://kovan.infura.io/v3/a17d484065334e38bd8b6475ca266c88",
    97: "https://data-seed-prebsc-2-s1.binance.org:8545",
    56: "https://bsc-dataseed4.binance.org",
    // 11155111:"https://sepolia.infura.io/v3/"
  },
};
