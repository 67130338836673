import token_abi from "./json/token.json";
import pool_abi from "./json/pool.json";
import game_abi from "./json/game.json";
import dao_abi from "./json/dao.json";
import gameDataManager_abi from "./json/gameDataManager.json";
import TimeGate_abi from "./json/TimeGate.json";
import LaborUnion_abi from "./json/LaborUnion.json";
import ERC20_abi from "./json/ERC20.json";
import record_abi from './json/record.json'
import news_abi from "./json/news.json"
import Web3 from "web3";
import record_new_abi from  './json/recordNew.json'
import multicall_abi from './json/multicall.json'

export let globalParam = {
  user: "",
  showDecimal: 4,
  gasM: 500000,
  gasL: 2000000,
};
export  const game2_timegate = "0xFdFFbeDc89897a471ee4a9F24CAE8B79ed975176"; //没用
export  const game3_timegate = "0xeb39671a97509741c6502db28258d630e75f60e1"; //new 
export const game2_laborunion = "0x9bd2c9f6fd7cfda2eb0785f38c70f11a031257e4";
export const game2_token = "0x1ee0b6e3af60a240ee3fcab67c69248ac1362ca9";  //gwc address
export const record_contract_addr = "0x616e95d038e3341c1e459814be372a301b309653" //record with some old txs
export const record_contract_addr_new = "0x5fcc7ab7f0bbb9906f5c924e6d709a3a271e430b" //record new
export const announcement_contract_addr = '0x8027ea6477a3173f244baff1ec73b4c5d88e7a4f' //announcement
export const multicall_contract_addr = "0x1c0931467ff759fbdc813cd41eded02aa9d56919"
//
//////////一下是heco的
// let husd = ''
// let link = "0x9e004545c59d359f6b7bfb06a26390b087717b42";
// let bch = "0xef3cebd77e0c52cb6f60875d9306397b5caca375";
// let wht = "0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f";
// let mdx = "0x25d2e80cb6b86881fd7e07dd263fb79f4abe033c";
//
// let husdusdt = "0xdff86B408284dff30A7CAD7688fEdB465734501C";
// let htusdt = "0x499B6E03749B4bAF95F9E70EeD5355b138EA6C31";
// let mdxusdt = "0x615E6285c5944540fd8bd921c9c8c56739Fd1E13";
// let linkusdt = "0x52a342015BAa2496A90A9bB6069D7692564132e6";
// let bchusdt = "0x1f0eC8e0096e145f2bf2Cb4950Ed7b52d1cbd35f";
//
// ///////////
//
//
// let poolhusdusdtlp = "0xE300f8A49c814a4f5BB414bfdfFa46DD9EE633BE";
// let poolhtusdtlp = "0x36d8fCe3841424672a8CD9743916884D1a5d48a8";
// let poolgwchusdtlp = "0x71EBDA903fcd0809D4f5CB492194a0ac491EAB37";
// let poolgwchtlp = "0x8fF392c6eC0dD5F183eCB1e11CCa6a9A9B845E3C";
// let gamethten = "0xecABEAB2301D7a3618DaBDa5516e3131Bdddc567";
// let gameththirty = "0x4137A39Fdf13F018758065C91e026eCc7E2A09b5";
// let gamehtusdtlp = "0x2920E663117BAe7fFA53E69A5DAc0fd8A2450506";
// let gamemdxusdtlp = "0xDaf8837dDE5A6B4092257c1D4De9EA9b51A78489";
// let gwchusdtlp = "0x0586581E3Cd2bb1fd0c99B4762Cb4e9957AB5494";
// let gwchtlp = "0x63540d61F83A21F91479DB916b5df202c30e8e4a";
//

///////////

/////////////一下是bnb的地址
// let bnb = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
let btyAddr = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
let bnb = "0x11cFb2532b93E66dEDAF9D322C86cADF9d87051e";
let wbnb = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
let usdt = "0x55d398326f99059ff775485246999027b3197955";
// let usdt = '0xfd8B50D448102e9B93d55af5368e81a7DC791424'
let uniFactory = "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73";
let eth = "0x2170Ed0880ac9A755fd29B2688956BD959F933F8";
let btc = "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c";
let fil = "0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153";
let ltc = "0x4338665cbb7b2485a8855a139b75d5e34ab0db94";
let dot = "0x7083609fce4d1d8dc0c979aab8c869ea2c873402";

let ethusdt = "0x531FEbfeb9a61D948c384ACFBe6dCc51057AEa7e";
let btcusdt = "0x3F803EC2b816Ea7F06EC76aA2B6f2532F9892d62";
let filusdt = "0x7DcC03aA0129B8b07f2C323391F8aBb0AFb76C52";
let ltcusdt = "0x36bb11B22CB3611483214289e19408701744FBf9";
let bnbusdt = "0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE";
let dotusdt = "0x8f6313e50aCA702c7e4e2F4cAba993505a8509fC";

// let gwcAddr = '0x142a8f64bb1b9Ae47513BF80db62659FFc0736eA'
// let gwcAddr = '0x7272ed65f3d4960F285E10525471c54F48D532d1' //bsc链
// let gwcAddr = '0x35BF3e4E158d815F2f6c4BeCE09264697227BB86'
let gwcAddr = "0x1ee0b6e3af60a240ee3fcab67c69248ac1362ca9";

let poolethusdtlp = "0xc3F132102586a775C88Da7bd3f67EE15323bBc2A";
let poolbtcusdtlp = "0xF5106893d5122E6Cb1E74314b8cD09E230373bF0";
let poolfilusdtlp = "0x3DC0CdfCEEc3810d877eAEc17963E7c23201f94b";
let poolgwcbtclp = "0xC8955b91F670F267cE490133Deff4970505eA7a0";
let poolltcusdtlp = "0x8ce429260DCb537d05146bb7d7834D13b606c3cA";
let poolbnbusdtclp = "0xd085EfC21B6bB1c94E3e972FF33A5D4f759eEC9C";
let poolgwcusdtlp = "0x7f6E4b08DF408cd3c863E9E96152301A219cde19";
let poolgwcethlp = "0x91d62D405d43C850BcE07180160E8fA9CDf04b63";
let poolgwcbnblp = "0x740314130aE2702bfC11A4d6b99dfc037c45644e";


let game0 = '0x89979fd6c45bd93961faf1269ec5dc33ea229aba'
let game1 = '0xede90017515d8e6e84ec5e6a13efcf57f93c45ac'
let game2 = '0xdaec9bed1a9d081186fcee5f60a30c917903f7f6'
let game3 = "0xdd454a37a760ba796ef4842064d4ce0e1698e306";
let game4 = "0x190f02816fe4a5471e80594f09654dded3936396";
let game5 = "0x457b8326697506c054f82e5f9e96f06509c207a8";
let game6 = "0x3db2ca8fb544e61b6c29bd6d93a3ce5db3bbd3d6";


let gameethusdtlp = "0x4FFB9F223b3BF6b94Bb4CD853c3112de7173CF69";
let gamegwcusdtlp = "0xd29BB7519061b3eFC93E9c60f1eF1e896AC0f8b5";
let gamebtcusdtlp = "0xB78E9e3EbB422c9cEC342cB143E3f139450ffE54";
let gamedotusdtlp = "0x9B03747e4Ca23CC19C92062f60e76311546c3ff7";
let gamefilusdtlp = "0x978Dd02D21471c7F01ccBaE472538C297C7C83e7";
let gamebnbusdtlp = "0xf9ABfcC76Fc648229B4FEb16459B3c764e47C110";
// let dao = '0xa331d1E8780C511460a07327898543B6246f113f'
let dao = "0x3ba924e19321901140fe80d1a978aa77e8bd14bb";

let gwcusdtlp = "0x6F5078AE3581A6CA17D4eDb283Ecf43B81B6a358";
let gwcbnblp = "0xe3c4D1C97261D1ed6ef4c465267917df4B57C8f0";
let gwcbtclp = "0x092A9391f0A0a71137e006C544b0e358c58487F0";
let gwcethlp = "0x7dF8000512149d50bedd0e60676F1221cF51B344";

let gameDataManager = "0x119981bdF0f4c23f5750912bB4dbEb6D4e02A9aD";

//单币挖矿
let gwcdaopool = dao;
let gwctodaopool = "";
let gwctwodaopool = "";

export const TempAddress = {
  lp: "0x17794342d82cb32a2b09536a9e96eaf255b75ce1",
  wbnb: wbnb,
  eth: usdt,
  btc: btc,
  usdt: usdt,
  bnb: bnb,
  fil: fil,
  // ethlp: ethusdt,
  ethlp: usdt,
  textgame: "0x69230F77A4508041432796E79B377Eb3689e8688",

  // btclp: btcusdt,
  btclp: usdt,
  dotlp: dotusdt,
  fillp: filusdt,
  gwc: gwcAddr,
  gwcbtclp: gwcbtclp,
  // gwcusdtlp: gwcusdtlp,
  gwcusdtlp: usdt,

  // bnbusdtlp:bnbusdt,
  bnbusdtlp: "0x35BF3e4E158d815F2f6c4BeCE09264697227BB86",

  gwcethlp: gwcethlp,
  gwcbnblp: gwcbnblp,
  dot: dot,

  //mint 新加
  ltclp: ltcusdt,
  //game
  i0game: game0,
  i1game: game1,
  i2game: game2,
  i3game: game3,
  i4game: game4,
  i5game: game5,
  i6game: game6,
  // usdt10game: '0xc38d800FBcE3Fa48727566DeFBB42C7323ABb1B0',
  // usdt30game: '0xc38d800FBcE3Fa48727566DeFBB42C7323ABb1B0',
  // bnb10game: gamebnbTen,
  // bnb30game: gamebnbThirty,

  ethusdtgame: gameethusdtlp,
  gwcusdtgame: gamegwcusdtlp,
  btcusdtgame: gamebtcusdtlp,
  // ethusdtgame: '0xc38d800FBcE3Fa48727566DeFBB42C7323ABb1B0',
  // gwcusdtgame: '0xc38d800FBcE3Fa48727566DeFBB42C7323ABb1B0',
  // btcusdtgame: '0xc38d800FBcE3Fa48727566DeFBB42C7323ABb1B0',

  dotusdtgame: gamedotusdtlp,
  filusdtgame: gamefilusdtlp,
  bnbusdtgame: gamebnbusdtlp,
  //pool
  ethlppool: poolethusdtlp,
  btclppool: poolbtcusdtlp,
  fillppool: poolfilusdtlp,
  ltclppool: poolltcusdtlp,
  gwclppool: poolgwcusdtlp,
  gwcethlppool: poolgwcethlp,
  // bnbusdtlppool:poolbnbusdtclp,
  bnbusdtlppool: "0x5f7ff608F66695D822daA11F857FdaE4c70fD800",

  gwcbnblppool: poolgwcbnblp,
  gwcdaopool: gwcdaopool,
  gwctodaopool: gwctodaopool,
  gwctwodaopool: gwctwodaopool,
  bnblppool: poolethusdtlp,
  mdxlppool: poolethusdtlp,
};

export const address = {
  lp: {
    address: TempAddress.lp,
    decimal: 18,
  },
  wbnb: {
    address: TempAddress.wbnb,
    decimal: 18,
  },
  eth: {
    address: TempAddress.eth,
    decimal: 18,
  },
  btc: {
    address: TempAddress.btc,
    decimal: 18,
  },
  usdt: {
    address: TempAddress.usdt,
    decimal: 18,
  },
  fil: {
    address: TempAddress.fil,
    decimal: 18,
  },
  gwc: {
    address: TempAddress.gwc,
    decimal: 18,
  },
  gwceth: {
    address: TempAddress.gwcethlp,
    decimal: 18,
  },
  gwcbnb: {
    address: TempAddress.gwcbnblp,
    decimal: 18,
  },

  bnb: {
    address: TempAddress.bnb,
    decimal: 18,
  },
  dot: {
    address: TempAddress.dot,
    decimal: 18,
  },

  ethlp: {
    address: TempAddress.ethlp,
    decimal: 18,
  },
  btclp: {
    address: TempAddress.btclp,
    decimal: 18,
  },
  dotlp: {
    address: TempAddress.dotlp,
    decimal: 18,
  },
  gwcbnblp: {
    address: TempAddress.gwcbnblp,
    decimal: 18,
  },
  bnbusdtlp: {
    address: TempAddress.bnbusdtlp,
    decimal: 18,
  },
  fillp: {
    address: TempAddress.fillp,
    decimal: 18,
  },

  ltclp: {
    address: TempAddress.ltclp,
    decimal: 18,
  },
  gwclp: {
    address: TempAddress.gwcusdtlp,
    decimal: 18,
  },
  gwcbtclp: {
    address: TempAddress.gwcbtclp,
    decimal: 18,
  },
  bnblp: {
    address: TempAddress.bnbusdtlp,
    decimal: 18,
  },
  // mdxlp: {
  //   address: TempAddress.gwcusdtlp,
  //   decimal: 18,
  // },

  //////pool

  ethlppool: TempAddress.ethlppool,

  // mdxpool: TempAddress.ethlppool,

  btclppool: TempAddress.btclppool,

  fillppool: TempAddress.fillppool,

  ltclppool: TempAddress.ltclppool,

  gwclppool: TempAddress.gwclppool,

  gwcbtclppool: TempAddress.gwclppool,
  gwcbnblppool: TempAddress.gwcbnblppool,
  bnbusdtlppool: TempAddress.bnbusdtlppool,
  gwcethlppool: TempAddress.gwcethlppool,

  /////game
  i0game: TempAddress.i0game,
  i1game: TempAddress.i1game,
  i2game: TempAddress.i2game,
  i3game: TempAddress.i3game,
  i4game: TempAddress.i4game,
  i5game: TempAddress.i5game,
  i6game: TempAddress.i6game,

  ethusdtgame: TempAddress.ethusdtgame,
  gwcusdtgame: TempAddress.gwcusdtgame,
  btcusdtgame: TempAddress.btcusdtgame,
  dotusdtgame: TempAddress.dotusdtgame,
  filusdtgame: TempAddress.filusdtgame,
  bnbusdtgame: TempAddress.bnbusdtgame,
  textgame: TempAddress.textgame,

  gwcdaopool: TempAddress.gwcdaopool,
  gwctodaopool: TempAddress.gwctodaopool,
  gwctwodaopool: TempAddress.gwctwodaopool,
};

export const initContracts = (coin) => {
  let contract;
  let web3 = new Web3(window.ethereum);

  if (coin.slice(-7) === "daopool") {
    contract = new web3.eth.Contract(dao_abi, address[coin]);
  } else if (coin.slice(-4) === "pool") {
    contract = new web3.eth.Contract(pool_abi, address[coin]);
  } else if (coin.slice(-4) === "game") {
    // console.log('game contract:',coin,'add:',address[coin])
    contract = new web3.eth.Contract(game_abi, address[coin]);
  } else {
    contract = new web3.eth.Contract(token_abi, address[coin].address);
  }
  return {
    web3,
    contract,
  };
};
export const initGameContracts = () => {
  let contract;
  let web3 = new Web3(window.ethereum);

  contract = new web3.eth.Contract(gameDataManager_abi, gameDataManager);

  return {
    web3,
    contract,
  };
};
//时空之门
export const initGame2TimeGateContracts = () => {
  let contract;
  let web3 = new Web3(window.ethereum);

  contract = new web3.eth.Contract(TimeGate_abi, game2_timegate);

  return {
    web3,
    contract,
  };
};
export const initGame3TimeGateContracts = () => {
  let contract;
  let web3 = new Web3(window.ethereum);

  contract = new web3.eth.Contract(TimeGate_abi, game3_timegate);

  return {
    web3,
    contract,
  };
};
//普通
export const initTokenContracts = (address) => {
  let contract;
  let web3 = new Web3(window.ethereum);

  contract = new web3.eth.Contract(token_abi, address);

  return {
    web3,
    contract,
  };
};
//工会
export const initLaborUnionContracts = () => {
  let contract;
  let web3 = new Web3(window.ethereum);

  contract = new web3.eth.Contract(LaborUnion_abi, game2_laborunion);

  return {
    web3,
    contract,
  };
};



export const initOtherContract = () => {

  let web3 = new Web3(window.ethereum);


  return {
    web3,
    contractRecord :  new web3.eth.Contract(record_abi, record_contract_addr),
    contractRecordNew : new web3.eth.Contract(record_new_abi,record_contract_addr_new),
    contractNews:new web3.eth.Contract(news_abi,announcement_contract_addr),
    contractMulticall:new web3.eth.Contract(multicall_abi,multicall_contract_addr),
    gwcAddr,
    btyAddr,
  };
};