import loadable from "react-loadable";
import Loading from "../components/Loading";
const importViw = (url) => {
  return loadable({
    loader: () => import(`${url}`),
    loading: Loading,
  });
};

const News = importViw("./News");
const Home = importViw("./Home");
const Game = importViw("./Game");
const Game2 = importViw("./Game2");
const Game3 = importViw("./Game3");
const Mining = importViw("./Mining");
const Gameinfo = importViw("./Gameinfo");
const Mininginfo = importViw("./Mininginfo");
const Labourunion = importViw("./Labourunion");
const Property = importViw("./Property");
const Dao = importViw("./Dao");
const Daoinfo = importViw("./Daoinfo");
const Game2Info = importViw("./Game2Info");
const Game3Info = importViw("./Game3Info");
const Game2Incomerecord = importViw("./Game2Incomerecord");
const Game3Incomerecord = importViw("./Game3Incomerecord");
const CreateLabourunion = importViw("./CreateLabourunion");
const LabourunionInfo = importViw("./LabourunionInfo");
const Withdraw = importViw("./Withdraw");
const Turnover = importViw("./Turnover");

export default {
  News,
  Home,
  Game,
  Mining,
  Gameinfo,
  Mininginfo,
  Labourunion,
  Property,
  Dao,
  Daoinfo,
  Game2,
  Game2Info,
  Game2Incomerecord,
  CreateLabourunion,
  LabourunionInfo,
  Withdraw,
  Turnover,
  Game3,
  Game3Info,
  Game3Incomerecord,
};
